import WebServiceRequest from '../Api/WebServiceRequest'

class ProductCommentGetByProductIdForAdmin extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ProductComment/GetByProductIdForAdmin')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class ProductCommentGetAllForAdmin extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ProductComment/GetAll')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class DeleteProductComment extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ProductComment/DeleteProductComment')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class ChangeProductCommentActivation extends WebServiceRequest {
        constructor (context) {
        super(context)
        super.setTag('ProductComment/ChangeCommentActivation')
    }
    setData(data){
        super.setRequestParamDataObj(data)
    }
}
export  {
    ProductCommentGetByProductIdForAdmin,
    ProductCommentGetAllForAdmin,
    DeleteProductComment,
    ChangeProductCommentActivation
};
